<template>
    <div class="content-wrapper">
        <validation-observer v-slot="{ handleSubmit }" ref="VForm">
            <b-form @submit.prevent="handleSubmit(doSubmit)">

                <div class="page-header page-header-sticky page-header-sticky-open">
                    <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                        <div class="page-header-info flex-1 py-3 text-uppercase">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                            <span>{{row.ap_fullname||"-"}}</span>
                                            <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                            <span class="ml-2 pl-2 border-left">{{rowReg.ar_reg_code||"-"}}</span>
                                            <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                        </h5>
                                    </div>
                                    <div class="phi-sub-info d-flex">
                                        <div class="phi-info d-flex flex-column">
                                            <small>Tanggal Lahir</small>
                                            <strong
                                                class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                        </div>
                                        <div class="phi-info d-flex flex-column pl-2 ml-3">
                                            <small>Usia</small>
                                            <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />
                                                ({{row.ap_gol_usia||"-"}})</strong>
                                        </div>
                                        <div class="phi-info d-flex flex-column pl-2 ml-3">
                                            <small>Jenis Kelamin</small>
                                            <strong
                                                class="font-weight-semibold">{{row.cg_label}}</strong>
                                        </div>
                                        <div class="phi-info d-flex flex-column pl-2 ml-3">
                                            <small>Opsi Pembayaran</small>
                                            <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                        </div>
                                        <div class="phi-info d-flex flex-column pl-2 ml-3">
                                            <small>Agama</small>
                                            <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="phi-info d-flex flex-column ml-auto">
                                    <small>Dokter</small>
                                    <h5 class="phi-main font-weight-semibold mb-0">
                                        <span>{{rowReg.bu_full_name||"-"}}</span>
                                    </h5>
                                    <div class="mb-2">
                                        <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
                    <i class="icon-arrow-up5 align-middle"></i>
                    <span class="align-middle">Sembunyikan Informasi Pasien</span>
                    </a>
                </div>

                <div class="content">
                    <div class="card">
                        <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Tambah Laporan Insiden</h6>
                        </div>
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label>Tgl. Kejadian<small class="txt_mandatory">*</small></label>
                                        <div class="input-group">
                                            <datepicker input-class="form-control transparent"
                                                placeholder="Pilih Tanggal Kejadian" class="my-datepicker"
                                                calendar-class="my-datepicker_calendar" v-model="row.ali_date">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i
                                                        class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                        <VValidate name="Tgl. Kejadian" v-model="row.ali_date" :rules="{required:1}" />
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label>Waktu. Kejadian<small class="txt_mandatory">*</small></label>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.times">
                                        </vue-timepicker>
                                        <div v-if="!row.times.HH && !row.times.mm" id="Waktu. Kejadian"
                                            class="label_error" style="">The Waktu. Kejadian field is required</div>
                                    </div>
                                </div>
                                <div class="col-md-8 col-lg-6">
                                    <div class="form-group">
                                        <label>Insiden<small class="txt_mandatory">*</small></label>
                                        <b-form-input :formatter="$parent.normalText" v-model="row.ali_insiden"
                                            type="text" class="form-control" />
                                        <VValidate name="Insiden" v-model="row.ali_insiden"
                                            :rules="toValidate(mrValidation.ali_insiden)" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Kronologi Insiden<small class="txt_mandatory">*</small></label>
                                        <b-textarea :formatter="$parent.normalText" v-model="row.ali_kronologi" rows="5"
                                            cols="3" class="form-control" placeholder="" spellcheck="false">
                                        </b-textarea>
                                        <VValidate name="Kronologi Insiden" v-model="row.ali_kronologi"
                                            :rules="toValidate(mrValidation.ali_kronologi)" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Jenis Insiden<small class="txt_mandatory">*</small></label>
                                                <v-select placeholder=" -- Jenis Insiden -- "
                                                    v-model="row.ali_jenis_insiden" :options="Config.mr.jenisInsiden"
                                                    label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                <VValidate name="Jenis Insiden" v-model="row.ali_jenis_insiden"
                                                    :rules="toValidate(mrValidation.ali_jenis_insiden)" />

                                            </div>
                                        </div>

                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label>Orang Pertama Yang Melaporkan Insiden<small
                                                        class="txt_mandatory">*</small></label>

                                                <div class="row">
                                                    <div :class="row.ali_reporter=='L'?'col-md-6': 'col-md-8'">
                                                        <v-select placeholder=" -- Pelapor Insiden -- "
                                                            v-model="row.ali_reporter"
                                                            :options="Config.mr.mReportInsiden" label="text"
                                                            :clearable="true" :reduce="v=>v.value"></v-select>
                                                        <VValidate name="Pelapor Insiden" v-model="row.ali_reporter"
                                                            :rules="toValidate(mrValidation.ali_reporter)" />
                                                    </div>

                                                    <div class="col-md-6" v-if="row.ali_reporter=='L'">
                                                        <b-form-input :formatter="$parent.normalText"
                                                            v-model="row.ali_reporter_lainnya" type="text"
                                                            class="form-control" />
                                                        <VValidate name="Pelapor Insiden Lainnya"
                                                            v-model="row.ali_reporter_lainnya"
                                                            :rules="toValidate(mrValidation.ali_reporter_lainnya)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Insiden terjadi pada<small
                                                        class="txt_mandatory">*</small></label>
                                                <div class="row">
                                                    <div :class="row.ali_occurs_in=='L'?'col-md-6': 'col-md-12'">
                                                        <v-select placeholder=" -- Insiden Pada -- "
                                                            v-model="row.ali_occurs_in" :options="Config.mr.mOccure"
                                                            label="text" :clearable="true" :reduce="v=>v.value">
                                                        </v-select>
                                                        <VValidate name="Insiden Pada" v-model="row.ali_occurs_in"
                                                            :rules="toValidate(mrValidation.ali_occurs_in)" />

                                                    </div>

                                                    <div class="col-md-6" v-if="row.ali_occurs_in=='L'">
                                                        <b-form-input :formatter="$parent.normalText"
                                                            v-model="row.ali_occurs_in_lainnya" type="text"
                                                            class="form-control" />
                                                        <VValidate name="Insiden Pada Lainnya"
                                                            v-model="row.ali_occurs_in_lainnya"
                                                            :rules="toValidate(mrValidation.ali_occurs_in_lainnya)" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Insiden menyangkut pasien<small
                                                        class="txt_mandatory">*</small></label>
                                                <div class="row">
                                                    <div :class="row.ali_menyangkut_pasien=='L'?'col-md-6': 'col-md-12'">
                                                        <v-select placeholder=" -- Menyangkut Pasien -- "
                                                            v-model="row.ali_menyangkut_pasien"
                                                            :options="Config.mr.insidenPasien" label="text"
                                                            :clearable="true" :reduce="v=>v.value"></v-select>
                                                        <VValidate name="Menyangkut Pasien"
                                                            v-model="row.ali_menyangkut_pasien"
                                                            :rules="toValidate(mrValidation.ali_menyangkut_pasien)" />
                                                    </div>
                                                    <div class="col-md-6" v-if="row.ali_menyangkut_pasien=='L'">
                                                        <b-form-input :formatter="$parent.normalText"
                                                            v-model="row.ali_menyangkut_pasien_lainnya" type="text"
                                                            class="form-control" />
                                                        <VValidate name="Menyangkut Pasien Lainnya"
                                                            v-model="row.ali_menyangkut_pasien_lainnya"
                                                            :rules="toValidate(mrValidation.ali_menyangkut_pasien_lainnya)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label>Tempat Insiden <small class="text-muted">(Tempat pasien
                                                        berada)</small><small class="txt_mandatory">*</small></label>

                                                <b-form-input placeholder="Lokasi Kejadian"
                                                    :formatter="$parent.normalText" v-model="row.ali_tempat_insiden"
                                                    type="text" class="form-control" />
                                                <VValidate name="Tempat Insiden" v-model="row.ali_tempat_insiden"
                                                    :rules="toValidate(mrValidation.ali_tempat_insiden)" />
                                            </div>
                                        </div>

                                        <div class="col-md-7">
                                            <div class="form-group">
                                                <label> Insiden terjadi pada pasien <small
                                                        class="txt_mandatory">*</small></label>
                                                <v-select placeholder=" -- Insiden Terjadi Pada Pasien -- "
                                                    v-model="row.ali_insiden_pada_pasien" :options="mInsiden"
                                                    label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                <VValidate name="Insiden Terjadi Pada Pasien"
                                                    v-model="row.ali_insiden_pada_pasien"
                                                    :rules="toValidate(mrValidation.ali_insiden_pada_pasien)" />
                                            </div>
                                        </div>

                                        <div class="col-md-4 col-lg-3">
                                            <div class="form-group">
                                                <label>Unit kerja penyebab<small class="txt_mandatory">*</small></label>
                                                <b-form-input placeholder="Unit Kejadian"
                                                    :formatter="$parent.normalText" v-model="row.ali_unit_kerja"
                                                    type="text" class="form-control" />
                                                <VValidate name="Unit Kejadian" v-model="row.ali_unit_kerja"
                                                    :rules="toValidate(mrValidation.ali_unit_kerja)" />
                                            </div>
                                        </div>

                                        <div class="col-md-4 col-lg-3">
                                            <div class="form-group">
                                                <label>Dampak insiden Terhadap Pasien<small
                                                        class="txt_mandatory">*</small></label>
                                                <v-select placeholder=" -- Dampak insiden Terhadap Pasien -- "
                                                    v-model="row.ali_akibat_insiden"
                                                    :options="Config.mr.akibatInsidenPadaPasien" label="text"
                                                    :clearable="true" :reduce="v=>v.value"></v-select>
                                                <VValidate name="Dampak insiden Terhadap Pasien"
                                                    v-model="row.ali_akibat_insiden"
                                                    :rules="toValidate(mrValidation.ali_akibat_insiden)" />
                                            </div>
                                        </div>

                                        <div class="col-md-4 col-lg-3">
                                            <div class="form-group">
                                                <label>Tindakan dilakukan oleh<small
                                                        class="txt_mandatory">*</small></label>
                                                <v-select placeholder=" -- Tindakan dilakukan oleh -- "
                                                    v-model="row.ali_tindakan_oleh" :options="Config.mr.tindakanOleh"
                                                    label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                                <VValidate name="Tindakan dilakukan oleh"
                                                    v-model="row.ali_tindakan_oleh"
                                                    :rules="toValidate(mrValidation.ali_tindakan_oleh)" />
                                            </div>

                                        </div>
                                        
                                        <div class="col-md-6 col-lg-3" v-if="row.ali_tindakan_oleh == 'Petugas Lainnya' || row.ali_tindakan_oleh == 'Tim'">
                                            <div class="form-group">
                                                <label>Tindakan oleh tim / lainnya<small
                                                        class="txt_mandatory">*</small></label>
                                                
                                                <b-form-input :formatter="$parent.normalText" v-model="row.ali_tindakan_by"
                                                type="text" class="form-control" />

                                                <VValidate name="Tindakan oleh tim / lainnya"
                                                    v-model="row.ali_tindakan_by"
                                                    :rules="{required : 1}" />
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <div class="form-group">
                                                    <label>Tindakan yang dilakukan segera setelah kejadian, dan hasilnya
                                                        <small class="txt_mandatory">*</small></label>
                                                    <b-textarea :formatter="$parent.normalText"
                                                        v-model="row.ali_tindakan_segera" rows="5" cols="3"
                                                        class="form-control" placeholder="" spellcheck="false">
                                                    </b-textarea>
                                                    <VValidate name="Tindakan yang dilakukan segera dilakukan"
                                                        v-model="row.ali_tindakan_segera"
                                                        :rules="toValidate(mrValidation.ali_tindakan_segera)" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>Apakah kejadian yang sama pernah terjadi di Unit Kerja
                                                            lain? <small class="txt_mandatory">*</small></label>

                                                        <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                                            v-model="row['ali_is_kajian_sama']" />

                                                        <VValidate name="Apakah kejadian yang sama pernah terjadi"
                                                            v-model="row.ali_is_kajian_sama"
                                                            :rules="toValidate(mrValidation.ali_is_kajian_sama)" />
                                                    </div>

                                                    <div class="form-group" v-if="row['ali_is_kajian_sama'] == 'Y'">
                                                        <label>Kapan ? dan Langkah / tindakan apa yang telah diambil
                                                            pada Unit kerja tersebut untuk
                                                            mencegah terulangnya kejadian yang sama? <small
                                                                class="txt_mandatory">*</small></label>

                                                        <b-textarea :formatter="$parent.normalText"
                                                            v-model="row.ali_langkah_dan_tindakan_unit_kerja" rows="5"
                                                            cols="3" class="form-control" placeholder=""
                                                            spellcheck="false"></b-textarea>
                                                        <VValidate name="Preventing"
                                                            v-model="row.ali_langkah_dan_tindakan_unit_kerja"
                                                            :rules="toValidate(mrValidation.ali_langkah_dan_tindakan_unit_kerja)" />

                                                    </div>
                                                </div>

                                                <div class="col-lg-5">
																									<div class="row">
																										<div class="col-md-7 col-lg-12">
																											<div class="form-group">
																													<label>Probabilitas<small
																																	class="txt_mandatory">*</small></label>
	
																													<v-select placeholder=" -- Probabilitas -- "
																															v-model="row.ali_probabilitas"
																															:options="Config.mr.probabilitasOpt" label="text"
																															:clearable="true" :reduce="v=>v.value"></v-select>
																													<VValidate name="Probabilitas" v-model="row.ali_probabilitas"
																															:rules="toValidate(mrValidation.ali_probabilitas)" />
	
																											</div>
																										</div>
																										<div class="col-md-5 col-lg-12">
																											<div class="form-group">
																													<label>GRADING<small class="txt_mandatory">*</small></label>
		
																													<v-select placeholder=" -- GRADING -- "
																															v-model="row.ali_grading" :options="Config.mr.gradingOpt"
																															label="text" :clearable="true" :reduce="v=>v.value">
																													</v-select>
																													<VValidate name="GRADING" v-model="row.ali_grading"
																															:rules="toValidate(mrValidation.ali_grading)" />
																											</div>
																										</div>
																									</div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer">
                            <div class="text-right">
                                <button type="button" @click="$router.back()" class="btn btn-light mr-1">Back</button>
                                <button type="submit" class="btn btn-primary">Submit <i
                                        class="icon-paperplane ml-2"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Datepicker from 'vuejs-datepicker'

export default{
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        Config: Object,
        mInsiden: Array
    },
    components:{ 
        Datepicker,
        VueTimepicker
    },
    computed: {
    },
    mounted() {    
        setTimeout(()=>{
            this.initSticky()
        },1000)
    },
    methods: {
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },
        doSubmit(){
            this.$refs['VForm'].validate().then(success=>{
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'add'
                            data.ali_ar_id = this.$parent.pageSlug

                            this.$parent.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+this.$parent.modulePage,
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.$parent.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok'
                                }).then(result => {
                                    if (result.value) {
                                        this.$router.push({name:this.$route.name}).catch(()=>{})
                                    }
                                })
                            }).catch(err=>{
                                this.$parent.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                            
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
            })
        },
        toValidate(val){
            return {...val}
        }
    }
}
</script>

<template>
    <div v-if="isList" class="content-wrapper">
        <bo-page-title></bo-page-title>
        <div class="content pt-0">
            <div class="card">
                <div class="bg-white card-header">
                    <div class="row align-items-center">
                        <div class="col-md-4 col-lg-5">
                            <div class="form-row">
                                <div class="col-lg-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                                <div class="col-lg-8">
                                    <div class="form-group mb-0 d-flex">
                                        <date-range-picker
                                            ref="picker"
                                            :locale-data="datePickerConfig.locale"
                                            :autoApply="datePickerConfig.autoApply"
                                            v-model="dateRange"
                                            :opens="'right'"
                                            :ranges="datePickerConfig.ranges"
                                            @update="updateValues"
                                        >
                                            <template v-slot:input="picker">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </template>
                                        </date-range-picker>
                                        <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md" v-if="moduleRole('export')">
                            <div class="text-right">
                                <a href="javascript:;"
                                 @click="openExport = true"
                                 class="btn bg-green-400 btn-labeled btn-labeled-left"><b><i class="icon-file-spreadsheet"></i></b> Export Excel</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-row">
                                <div class="col-md-6 col-lg-4" v-if="isshowTable('mpo_name')">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="table-responsive sticky-table">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th v-if="isshowTable('ar_reg_code')">No. Kedatangan</th>
                                <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                                <th v-if="isshowTable('mpo_name')">Nama Poli</th>
                                <th v-if="isshowTable('ar_reg_date')">Tanggal Daftar</th>
                                <th v-if="isshowTable('ap_code')">No. RM</th>
                                <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                                <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                                <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                                <th v-if="isshowTable('ap_usia')">Usia</th>
                                <th v-if="isshowTable('cg_label')">Jenis Kelamin</th>
                                <th v-if="isshowTable('ar_penunjang')">Penunjang</th>
                                <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody v-if="!loadingTable" >
                            <template v-for="(v,k) in dataReg" >
                                <tr :key="k">
                                    <td>{{k+1}}</td>
                                    <td v-if="isshowTable('ar_reg_code')">
                                    {{v.ar_reg_code||"-"}}
                                    <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                    <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                    <td v-if="isshowTable('ap_fullname')">
                                        <a href="javascript:;" v-if="moduleRole('view') && v.ar_is_have_laporan_insiden == 'Y'" @click="viewDetail(v.ar_id)" data-popup="tooltip" v-b-tooltip.hover title="Lihat Detail Laporan Insiden" class="font-weight-semibold">{{uppercaseWord(v.ap_fullname)||'-'}}</a>
                                        <span v-else>{{uppercaseWord(v.ap_fullname)||'-'}}</span>
                                        <p>{{v.ap_nik||"-"}}</p>
                                    </td>
                                    
                                    <td v-if="isshowTable('mpo_name')">{{uppercaseWord(v.mpo_name)||"-"}}</td>
                                      
                                    <td v-if="isshowTable('ar_reg_date')">
                                    {{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>      
                                    <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                    <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                    <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
                                    <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                    <td v-if="isshowTable('ap_usia')" v-b-tooltip.hover>
                                    {{v.ap_usia_with_ket||"-"}}
                                    <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                    </td>
                                    <td v-if="isshowTable('cg_label')">{{v.cg_label}}</td>
                                    <td v-if="isshowTable('ar_penunjang')">
                                        <template v-if="v.ar_penunjang">
                                            <div v-for="(v1,k1) in (v.ar_penunjang||[])" :key="k1" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1" :key="k1">{{v1}}</span>
                                            </div>
                                        </template>
                                        <div v-else> - </div>
                                    </td>
                                    <td>
                                        <router-link class="btn alpha-danger border-danger text-danger-800 btn-icon rounded-round"
                                            v-if="moduleRole('add') && v.isCanAdd"
                                            :to="{name: $route.name, params: {pageSlug: v.ar_id}}"
                                            data-toggle="tooltip" data-placement="top" v-b-tooltip.hover :title="'Tambah Laporan Insiden'"><i
                                        class="icon-plus-circle2"></i></router-link>
                                        <span v-else> - </span>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody v-if="loadingTable">     
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                        <tbody v-if="loadingInvinite">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                        </tbody>
                        <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                            <tr>
                                <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                    Data Tidak Ditemukan
                                </th>
                            </tr>                
                        </tbody>                
                    </table>
                    <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
        <b-modal 
            v-model="modalDetail"
            :title="'Detail Laporan Insiden'"
            size="lg"
            ok-title="Tutup"
            ok-only
            @ok="modalDetail=false"

        >
            <div class="row">
                <div class="col-md-12">
                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                        <li v-for="(vP,kP) in rowDetail||[]" :key="kP+'tab'" class="nav-item"><a href="javascript:;" @click="changeTab(kP)" :class="activeTab == kP ? 'nav-link active' : 'nav-link'" data-toggle="tab">#{{vP.bul_name}}</a></li>
                    </ul>
                </div>
            </div>
        
            <div class="tab-content">
                <div v-for="(vP,kP) in rowDetail||[]" :key="kP+'navAnat'" :class="activeTab == kP ? 'tab-pane fade-show active' : 'tab-pane fade'">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li @click="activeTabInsiden = 0" :class="activeTabInsiden == 0 ? 'nav-link active' : 'nav-link'">Data Insiden</li>
                                <li @click="activeTabInsiden = 1" :class="activeTabInsiden == 1 ? 'nav-link active' : 'nav-link'">Data Pasien</li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="activeTabInsiden == 0">
                        <div class="row">
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Tanggal dan Waktu Kejadian</h4>
                                        <p>{{rowDetail[kP].ali_date | moment("DD MMM YYYY")}} {{rowDetail[kP].ali_time||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Insiden</h4>
                                        <p>{{rowDetail[kP].ali_insiden||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Jenis Insiden</h4>
                                        <p>{{getConfigDynamic(Config.mr.jenisInsiden,rowDetail[kP].ali_jenis_insiden)||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Kronologi Insiden</h4>
                                        <p>{{rowDetail[kP].ali_kronologi||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Insiden Terjadi Pada</h4>
                                        <p v-if="rowDetail[kP].ali_occurs_in !== 'L'">{{rowDetail[kP].ali_occurs_in||"-"}}</p>
                                        <p v-else>{{rowDetail[kP].ali_occurs_in_lainnya||"-"}}</p>
                                    
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Insiden Menyangkut Pasien</h4>
                                        <p v-if="rowDetail[kP].ali_menyangkut_pasien !== 'L'">{{rowDetail[kP].ali_menyangkut_pasien||"-"}}</p>
                                        <p v-else>{{rowDetail[kP].ali_menyangkut_pasien_lainnya||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Tempat Insiden</h4>
                                        <p>{{rowDetail[kP].ali_tempat_insiden||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Insiden Terjadi pada Pasien</h4>
                                        <p>{{rowDetail[kP].mi_name||"-"}}</p>

                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Unit Kerja Penyebab</h4>
                                        <p>{{rowDetail[kP].ali_unit_kerja||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Dampak insiden terhadap Pasien</h4>
                                        <p>{{rowDetail[kP].ali_akibat_insiden||"-"}}</p>

                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" v-if="rowDetail[kP].ali_is_kajian_sama == 'Y'">
                                    <div class="result_tab">
                                        <h4>Tindakan yang dilakukan segera setelah kejadian, dan hasilnya</h4>
                                        <p>{{rowDetail[kP].ali_langkah_dan_tindakan_unit_kerja||"-"}}</p>
                                    </div>
                                    <div class="result_tab mt-2">
                                        <h4>Tindakan dilakukan oleh</h4>
                                        <p>{{rowDetail[kP].ali_tindakan_oleh||"-"}}</p> <br/>
                                        <p v-if="rowDetail[kP].ali_tindakan_oleh == 'Petugas Lainnya' || rowDetail[kP].ali_tindakan_oleh == 'Tim'">{{rowDetail[kP].ali_tindakan_by||"-"}}</p> <br/>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                <tr>
                                    <td colspan="2">
                                    <div class="result_tab">
                                        <h4>Apakah kejadian yang sama pernah terjadi di Unit Kerja lain?</h4>
                                        <p>{{rowDetail[kP].ali_is_kajian_sama == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                    </div>
                                    <div class="result_tab mt-2">
                                        <h4>Kapan? dan Langkah / tindakan apa yang telah diambil pada Unit kerja tersebut untuk mencegah terulangnya kejadian yang sama?</h4>
                                        <p>{{rowDetail[kP].ali_langkah_dan_tindakan_unit_kerja||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Probabilitas</h4>
                                        <p>{{rowDetail[kP].ali_probabilitas||"-"}}</p>
                                    </div>
                                    </td>
                                    <td width="50%">
                                    <div class="result_tab">
                                        <h4>Grading</h4>
                                        <p>{{rowDetail[kP].ali_grading||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <div v-if="activeTabInsiden == 1">
                        <div class="card-header">
                            <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered table-sm text-uppercase">
                            <tbody>
                                <tr>
                                <td width="33%">
                                    <div class="result_tab">
                                    <h4>No. Rekam Medis</h4>
                                    <p>{{rowDetail[kP].ap_code||"-"}}</p>
                                    </div>
                                </td>
                                <td width="33%">
                                    <div class="result_tab">
                                    <h4>Nama</h4>
                                    <p>{{rowDetail[kP].ap_fullname||"-"}}</p>
                                    </div>
                                </td>
                                <td width="33%">
                                    <div class="result_tab">
                                    <h4>NIK</h4>
                                    <p>{{rowDetail[kP].ap_nik}}</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Tempat, Tanggal Lahir</h4>
                                    <p>{{rowDetail[kP].ap_pob||"-"}}, {{rowDetail[kP].ap_dob | moment("DD MMM YYYY")}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Usia</h4>
                                    <p>{{rowDetail[kP].ap_usia_with_ket||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Kategori Usia</h4>
                                    <p>{{rowDetail[kP].ap_gol_usia||"-"}}</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{rowDetail[kP].cg_label||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Agama</h4>
                                    <p>{{rowDetail[kP].ma_name||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Suku</h4>
                                    <p>{{rowDetail[kP].ap_suku||"-"}}</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Pekerjaan</h4>
                                    <p>{{rowDetail[kP].mp_name||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Pendidikan</h4>
                                    <p>{{rowDetail[kP].mpen_name||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Nama Ibu Kandung</h4>
                                    <p>{{rowDetail[kP].ap_nama_ibu||"-"}}</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>No. Telpon</h4>
                                    <p>{{rowDetail[kP].ap_phone_number||"-"}}</p>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <div class="result_tab">
                                    <h4>Email</h4>
                                    <p v-if="rowDetail[kP].ap_email">{{rowDetail[kP].ap_email.trim()||"-"}}</p>
                                    <p v-else> - </p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <div class="result_tab">
                                    <h4>Status Pernikahan</h4>
                                    <p v-if="rowDetail[kP].ap_status_pernikahan == 1">Belum Kawin</p>
                                    <p v-else-if="rowDetail[kP].ap_status_pernikahan == 2">Kawin</p>
                                    <p v-else-if="rowDetail[kP].ap_status_pernikahan == 3">Cerai Hidup</p>
                                    <p v-else-if="rowDetail[kP].ap_status_pernikahan == 4">Cerai Mati</p>
                                    <p v-else> - </p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Bahasa yang Dikuasai</h4>
                                    <p>{{rowDetail[kP].ap_language||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Golongan Darah</h4>
                                    <p v-if="rowDetail[kP].ap_gol_darah">{{rowDetail[kP].ap_gol_darah.trim()||"-"}}</p>
                                    <p v-else> - </p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td colspan="3">
                                    <div class="result_tab">
                                    <h4>Alamat Domisili</h4>
                                    <p>-</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td colspan="3">
                                    <div class="result_tab">
                                    <h4>Alamat Sesuai KTP</h4>
                                    <p>-</p>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        
        <!--
        @click="doSubmitExport" 
        -->

        <b-modal 
            v-model="openExport"
            :title="'Export Data'"
            size="sm"
            ok-only
            ok-title="Tutup"
        >
            <div class="row">
                <div class="col-md-12">
                    <div class="wrap_statistic_line">
                    <div class="row g-2">
                        <div class="col-md-12">
                            <div class="task_wrap c-pointer" @click="doSubmitExport">
                                <div class="row align-items-center">
                                <div class="col-md-3 col-3">
                                    <a href="javascript:;" class="shape_count" style="background-color:#162c5e;">
                                    <p><i class="icon-calendar"></i></p>
                                    </a>
                                </div>
                                <div class="col-md-9 col-9">
                                    <p>Export Data Per Periode</p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="task_wrap c-pointer" @click="doSubmitExportPerUnit">
                                <div class="row align-items-center">
                                <div class="col-md-3 col-3">
                                    <a href="javascript:;" class="shape_count" style="background-color:#8D7B68;">
                                    <p><i class="icon-users2"></i></p>
                                    </a>
                                </div>
                                <div class="col-md-9 col-9">
                                    <p>Export Data Per Unit</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
    <div class="w-100" v-else>
        <Form :row.sync="row" v-bind="passToSub" />
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'
import Form from './Form.vue'

export default {
    extends: GlobalVue,
    data() {
        return {
            // LIST COMP ==============
            mPoli: [],
            dataReg: [],
            mInsiden: [],
            rowEdit: {},
            rowReg: {},
            loadingInvinite: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            selectedFilter: [
                'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
            ],
            acceptedFilter: [
                'ar_reg_code','ap_fullname','ars_name','mpo_name','ar_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','ar_penunjang','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },            
            modalDetail: false,
            rowDetail: [],
            activeTab: 0,
            activeTabInsiden: 0,
            openExport: false


            // LIST COMP ================
        }
    },
    computed:{
        passToSub(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                mInsiden: this.mInsiden
            })
        },
    },
  	components:{
        DateRangePicker,
        Form
    },
    methods: {
        changeTab(e){
            this.activeTab = e
        },
        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },500),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query

            let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")

            if(this.isList){
                this.doConvertDate()
                if((+this.$route.query.page||1) == 1){
                    this.loadingTable = true
                }
            }else{
                this.loadingOverlay = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage+slugs, 
                {
                    params: Object.assign({
                        selectedFilter: this.isList ? this.selectedFilter.join(",") : '',
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data    
                if(this.isList){
                    this.loadingTable = false
                    this.loadingInvinite = false
                    this.totalData = resp.dataReg.total

                    if((+this.$route.query.page||1) !== 1){
                        let data = resp.dataReg.data
                        for(let i = 0; i < (data||[]).length; i++){
                            this.dataReg.push(data[i])
                        }
                    }else{
                        this.dataReg = resp.dataReg.data
                    }   
                }else{
                    this.loadingOverlay = false
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                    
                    if(!this.isFound){
                        this.$router.push({name : this.$route.name}).catch(()=>{})
                    }
                }
            })
            
            Gen.apiRest(
                "/get/"+'Master'+
                '/poli'
            ).then(res=>{
                let resp = res.data
                this.mPoli = resp.mPoli
            })
        },
        handleScroll(event){
            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 5)) {
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'LaporanInsiden' && this.isList){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
        },

        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        
        viewDetail(ar_id){
            this.loadingOverlay = true
            let data = {
                id: ar_id,
                type: "view-detail"
            }
            Gen.apiRest(
                "/do/"+'LaporanInsiden',
                {data:data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                this.modalDetail = true
                this.rowDetail = resp.row
                this.loadingOverlay = false       
            })
        },

        doSubmitExport(){
            this.loadingOverlay = true
            let data = {exptype: 'xlsx', type: "export"}
            let self = this

            $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${'laporanInsiden'}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                xhrFields:{
                    responseType: 'blob'
                },
                success: data => 
                {
                    self.loadingOverlay = false
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = `Laporan-Insiden-${moment().format("YYYY-MM-DD")}.xlsx`
                    link.click()
                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
            })
        },

        doSubmitExportPerUnit(){
            // this.loadingOverlay = true
            let data = {exptype: 'xlsx', type: "export"}
            let self = this

            $.ajax({
                type: "POST",
                url: process.env.VUE_APP_API_URL + `/report/${'laporanInsidenperUnit'}?token=IXs1029102asoaksoas102901290`,
                data: data,
                cache: false,
                xhrFields:{
                    responseType: 'blob'
                },
                success: data => 
                {
                    self.loadingOverlay = false
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(data)
                    link.download = `Laporan-Insiden-Perunit-${moment().format("YYYY-MM-DD")}.xlsx`
                    link.click()
                },
                fail: data => {
                    self.loadingOverlay = false
                    alert('Not downloaded')
                }
            })
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },

        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            const responsiveTableHead = responsiveTable.querySelector('thead')
            const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
            const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
            const tableOffsetTop = responsiveTable.offsetTop + responsiveTable.offsetParent.offsetTop
            responsiveTableHead.style.position = 'relative'
            responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
            window.addEventListener('scroll', e => {
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                if (document.documentElement.scrollTop > tableOffsetTop) {
                    responsiveTableHead.style.top = (document.documentElement.scrollTop - tableOffsetTop)+64+'px'
                }else{
                    responsiveTableHead.style.top = '0px'
                }
            })
            Array.from(responsiveTableScrollActions).forEach(action => {
                action.addEventListener('click', e => {
                    const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                     
                    responsiveTable.scroll({
                        left: scrollAmount,
                        behavior: 'smooth'
                    })
                })
            })
        },
    },
    mounted() {
        if(this.isList){
            this.filter.page = 1
            if(this.$route.query.page != 1){
                let url = {
                    name:this.$route.name,
                    params: (this.$route.params||{}),
                    query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
                }
                this.$router.push(url).catch(()=>{})
            }else{
                this.apiGet()
            }
        }else{
            this.apiGet()
        }
        
        setTimeout(()=>{
            if(this.isList){
                this.setSlide()
            }
        },1000)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
    },
    watch:{
        $route(){
            this.apiGet()
            setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
</style>